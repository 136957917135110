import React from 'react'
import { Helmet } from 'react-helmet'
import '../../styles/page.less'
import Logo from '../../images/logo.svg'
import img1 from './img/img1.jpg'
import img2 from './img/img2.jpg'
import img3 from './img/img3.jpg'
import img4 from './img/img4.jpg'
import img5 from './img/share.jpg'

const DESCRIPTION =
  'Как заработать риэлтору больше? Узнай сколько зарабатывают риелторы на ипотечных сделках по недвижимости!'

const HowToMakeMoneyAsRieltor = () => {
  return (
    <>
      <Helmet>
        <title>
          Как зарабатывать риэлторам - сколько зарабатывает риэлтор недвижимости
          на сделках
        </title>

        <meta name="description" content={DESCRIPTION} />
        <meta property="og:description" content={DESCRIPTION} />
      </Helmet>

      <div className="page">
        <div className="page--header">
          <a href="/" target="_blank">
            <img src={Logo} alt={'logo'} loading="lazy" />
          </a>
        </div>
        <article className="page--content">
          <h1>Как заработать деньги риэлтору</h1>
          <p className="ul-header">
            Риэлтер (маклер)&nbsp;&mdash; высокодоходная современная профессия.
            Правда, распространенное убеждение, что в&nbsp;этой сфере можно
            зарабатывать много и&nbsp;без особых усилий, довольно спорно.
            Возможно, когда-то так и&nbsp;было, но&nbsp;сейчас ситуация
            поменялась:
          </p>
          <ul>
            <li>
              на&nbsp;рынке серьёзно возросла конкуренция, агентств
              и&nbsp;частных маклеров становится всё больше;
            </li>
            <li>
              принятие решения по&nbsp;сделке купли-продажи стало занимать
              не&nbsp;один месяц, а&nbsp;значит, полученные комиссионные
              &laquo;размазываются&raquo; на&nbsp;весь этот период;
            </li>
            <li>
              для начинающих специалистов вход в&nbsp;профессию может длиться
              от&nbsp;полугода до&nbsp;полутора лет, и&nbsp;довольно долго
              приходиться работать на&nbsp;минимальном окладе или вообще без
              него.
            </li>
          </ul>
          <p className="page--main-p">
            Давайте рассмотрим нюансы профессии, то, как риэлтор может
            заработать деньги и&nbsp;сколько.
          </p>
          <section className="page--section">
            <h2>Сколько зарабатывает риэлтор?</h2>
            <p>
              Доход маклера зависит от&nbsp;многих факторов. Самый
              очевидный&nbsp;&mdash; регион. Если в&nbsp;Москве на&nbsp;сделках
              с&nbsp;недвижимостью можно заработать 200&minus;300 тысяч рублей
              в&nbsp;месяц и&nbsp;более, то&nbsp;где-нибудь
              в&nbsp;Астрахани&nbsp;&mdash; 40&minus;50&nbsp;тыс. рублей.
            </p>
            <p>
              <img
                src={img1}
                alt="Сколько зарабатывает риэлтор?"
                loading="lazy"
              />
            </p>
            <p className="ul-header">Также имеют значение: </p>
            <ul>
              <li>
                Опыт в&nbsp;профессии. Новичку, как мы&nbsp;уже сказали,
                на&nbsp;первых порах приходится работать практически бесплатно.
                Начинающие риэлторы на&nbsp;рынке купли-продажи часто получают
                свои первые деньги только через несколько месяцев,
                в&nbsp;то&nbsp;время как маклер со&nbsp;стажем за&nbsp;месяц
                может закрыть 3&ndash;6&nbsp;сделок;
              </li>
              <li>
                Сегмент рынка. Наибольший доход&nbsp;&mdash; в&nbsp;коммерческой
                недвижимости, так как суммы сделок здесь внушительные. Хорошо
                заработать можно также на&nbsp;загородном жилье и&nbsp;квартирах
                эконом-класса (в&nbsp;последнем случае, конечно,
                не&nbsp;за&nbsp;счёт крупных сумм отдельных сделок,
                а&nbsp;благодаря их&nbsp;количеству);
              </li>
              <li>
                Тип сделок. Маклер, занимающийся арендой, получает гораздо
                меньше специалиста, который сопровождает операции купли-продажи.
                Но&nbsp;в&nbsp;сделках по&nbsp;аренде есть своё преимущество:
                доход здесь стабильнее и&nbsp;доступен даже начинающим.
                И&nbsp;не&nbsp;нужно ждать полгода, чтобы получить первые
                комиссионные;
              </li>
              <li>
                Частота заключения договоров. Можно каждый месяц закрывать
                несколько небольших сделок, а&nbsp;можно раз
                в&nbsp;полгода&nbsp;&mdash; одну крупную. И&nbsp;это ещё одна
                особенность этой профессии: здесь &laquo;то&nbsp;густо,
                то&nbsp;пусто&raquo;. Вполне реален вариант, когда
                ты&nbsp;несколько месяцев сидишь без денег, а&nbsp;потом
                получаешь сумму, сравнимую со&nbsp;стоимостью хорошей машины;
              </li>
              <li>
                Активность и&nbsp;профессионализм. Риэлтера &laquo;ноги
                кормят&raquo;&nbsp;&mdash; чем больше показано квартир,
                обработано заявок, сделано звонков, тем выше шанс заключения
                сделки и&nbsp;получения денег, тем быстрее клиент принимает
                решение. Необходимы также вежливость, коммуникабельность, умение
                убеждать, ответственность.
              </li>
            </ul>
          </section>
          <section className="page--section">
            <h2>Как зарабатывают риэлторы, работая на себя или в агентстве?</h2>
            <p>
              Доход маклера стандартно состоит из&nbsp;опредёленного процента
              от&nbsp;суммы сделок. При работе на&nbsp;агентство может быть
              также небольшой оклад. На&nbsp;операциях по&nbsp;купле-продаже
              посредник получает выплату в&nbsp;размере 4&minus;6% от&nbsp;суммы
              продажи, по&nbsp;аренде&nbsp;&mdash; единоразово 50&minus;100%
              от&nbsp;суммы арендной платы за&nbsp;месяц.
            </p>
            <p>
              <img src={img2} alt="Как зарабатывают риэлторы" loading="lazy" />
            </p>
            <p className="ul-header">
              Однако при работе на&nbsp;агентство часть комиссионных придётся
              отдать ему, и&nbsp;чаще всего эта часть составляет 50&minus;65%
              дохода. Взамен риэлтерская компания предоставляет своим
              сотрудникам различные &laquo;плюшки&raquo;:
            </p>
            <ul>
              <li>небольшой стабильный оклад;</li>
              <li>базу клиентов;</li>
              <li>оборудованное рабочее место;</li>
              <li>услуги нотариуса, юриста;</li>
              <li>помощь и&nbsp;наставничество опытных коллег;</li>
              <li>автоматизированные системы, облегчающие работу.</li>
            </ul>
            <p>
              Новичку часто имеет смысл устраиваться именно
              в&nbsp;агентство&nbsp;&mdash; ведь именно здесь он&nbsp;может
              приобрести необходимый опыт и&nbsp;потом отправиться
              &laquo;в&nbsp;свободное плавание&raquo;.
            </p>
            <p>
              А&nbsp;вот специалист со&nbsp;стажем и&nbsp;наработанной базой
              клиентов гораздо больше может заработать сам. Правда, все расходы
              и&nbsp;ответственность также придется нести самому.
            </p>
          </section>
          <section className="page--section">
            <h2>Как увеличить свой доход?</h2>
            <p>
              <img src={img3} alt="Как увеличить свой доход?" loading="lazy" />
            </p>
            <p>
              Независимо от&nbsp;того, новичок вы&nbsp;в&nbsp;риэлторской
              деятельности или профессионал со&nbsp;стажем, работаете частным
              образом или в&nbsp;агентстве, можно получать больше, несмотря
              на&nbsp;высокую конкуренцию в&nbsp;отрасли. Есть несколько
              абсолютно законных способов. Каких? Давайте посмотрим.
            </p>
          </section>
          <section className="page--section">
            <h3>Сотрудничество с партнёрами</h3>
            <p className="ul-header">
              Покупателю квартиры, дома, загородного участка и&nbsp;даже
              квартиросъёмщику чаще всего нужны дополнительные услуги, причем
              это может быть как на&nbsp;стадии выбора объекта, так и&nbsp;после
              сделки. Риэлтер может заключить соглашение с&nbsp;компаниями,
              которые оказывают такие услуги, и&nbsp;получать от&nbsp;них кэшбэк
              за&nbsp;каждого клиента. Вот самое очевидное, что может
              понадобиться вашему покупателю (или продавцу),
              а&nbsp;в&nbsp;скобках&nbsp;&mdash; какого партнёра нужно искать:
            </p>
            <ul>
              <li>
                выбор самой выгодной программы ипотеки (ипотечный брокер);
              </li>
              <li>
                решение проблемных юридических вопросов по&nbsp;купле-продаже
                (юридическая компания или частный юрист);
              </li>
              <li>страхование нового жилья (страховые компании);</li>
              <li>
                перевозка мебели и&nbsp;вещей во&nbsp;время переезда
                (грузоперевозчик);
              </li>
              <li>
                уборка до&nbsp;вселения в&nbsp;новое жильё (клининговая
                компания, частные специалисты по&nbsp;клинингу);
              </li>
              <li>
                ремонт квартиры (ремонтные бригады, строительные компании);
              </li>
              <li>
                мебель на&nbsp;кухню, встроенные шкафы (компании
                по&nbsp;изготовлению мебели на&nbsp;заказ);
              </li>
              <li>
                замена сантехники, проверка и&nbsp;ремонт
                электропроводки&nbsp;&mdash; список можно продолжать ещё
                и&nbsp;ещё.
              </li>
            </ul>
          </section>
          <section className="page--section">
            <h3>Сопутствующие услуги</h3>
            <p>
              <img src={img4} alt="Сопутствующие услуги" loading="lazy" />
            </p>
            <p className="ul-header">
              Почему&nbsp;бы риэлтору не&nbsp;оказывать некоторые дополнительные
              услуги самому и&nbsp;заработать на&nbsp;этом? Например, проблемные
              юридические вопросы профессиональный маклер вполне способен решить
              самостоятельно. Объемные и&nbsp;сложные услуги можно предоставлять
              за&nbsp;дополнительную плату. Это может быть:
            </p>
            <ul>
              <li>оценка реальной стоимости объекта;</li>
              <li>
                выведение квартиры из&nbsp;жилого фонда, если новый собственник
                планирует использовать её&nbsp;для бизнеса;
              </li>
              <li>
                проверка чистоты документов, истории объекта&nbsp;&mdash;
                не&nbsp;ждут&nbsp;ли покупателя неприятные сюрпризы в&nbsp;виде
                претензий на&nbsp;наследство, не&nbsp;выписанных жильцов
                и&nbsp;т.&nbsp;п.
              </li>
            </ul>
            <p>
              Простые услуги, как, например, помочь с&nbsp;регистрацией новой
              собственности, можно оказывать бесплатно и&nbsp;таким образом
              выделиться на&nbsp;фоне конкурентов. А&nbsp;это позволит привлечь
              больше клиентов и&nbsp;больше заработать.
            </p>
          </section>
          <section className="page--section">
            <h2>Автоматизация работы или готовый способ заработать риэлтеру</h2>
            <p>
              Ещё один хороший способ&nbsp;&mdash; это заработок
              на&nbsp;специальном партнёрском сервисе &laquo;
              <a href="/" target="_blank">
                Ипотека.Центр
              </a>
              &raquo;. Вы&nbsp;можете презентовать своим клиентам услугу подбора
              выгодной ипотечной программы (фактически, услуги ипотечного
              брокера) и&nbsp;при этом получить агентское вознаграждение сервиса
              в&nbsp;размере до&nbsp;1% от&nbsp;суммы сделки.
            </p>
            <p>
              <img
                src={img5}
                alt="Автоматизация работы или готовый способ заработать риэлтеру"
                loading="lazy"
              />
            </p>
            <p className="ul-header">
              &laquo;Ипотека.Центр&raquo;&nbsp;&mdash; это автоматизированная
              ипотечная платформа, которая позволяет, не&nbsp;выходя
              из&nbsp;дома, в&nbsp;онлайн-режиме пройти все этапы ипотечной
              сделки:
            </p>
            <ul>
              <li>
                при помощи специального умного калькулятора подобрать наиболее
                подходящие вашему клиенту программы ипотеки;
              </li>
              <li>
                подать заявку клиента в&nbsp;банк (или несколько)
                на&nbsp;получение кредита, причём для любого банка анкета единой
                формы;
              </li>
              <li>
                отслеживать, одобрена&nbsp;ли заявка, без звонков и&nbsp;визитов
                в&nbsp;банк;
              </li>
              <li>
                заказать услугу оценки у&nbsp;подключённых к&nbsp;платформе
                оценочных компаний;
              </li>
              <li>застраховать объект;</li>
              <li>
                назначить встречу с&nbsp;сотрудником банка для оформления сделки
                по&nbsp;ипотеке.
              </li>
            </ul>
            <p>
              Все действия: заявка, оценка, страховка, решение&nbsp;&mdash;
              делаются в&nbsp;одном окне. Даже если вы&nbsp;никогда раньше
              не&nbsp;сталкивались с&nbsp;оформлением ипотеки, работать
              в&nbsp;сервисе довольно просто. Есть обучение платформе,
              а&nbsp;для решения возникающих в&nbsp;ходе процесса вопросов
              за&nbsp;вами закрепляется личный куратор.
            </p>
            <p>
              Ещё одно преимущество платформы &laquo;
              <a href="/" target="_blank">
                Ипотека.Центр
              </a>
              &raquo;&nbsp;&mdash; это возможность общаться с&nbsp;опытными
              экспертами по&nbsp;ипотеке, получать от&nbsp;них ценный опыт
              и&nbsp;советы на&nbsp;мастер-классах, бизнес-завтраках, вебинарах
              и&nbsp;т.&nbsp;д.
            </p>
          </section>
        </article>
      </div>
    </>
  )
}

export default HowToMakeMoneyAsRieltor
